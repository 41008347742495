//商品概况
<template>
  <div class="container">
    <div class="over_head">
      <div class="head_one">
        <div class="head_title">商品概况</div>
        <div class="head_item">
          <div class="item_one">出售中的商品数</div>
          <div>{{ MainInfo.goods_count }}</div>
        </div>
        <div class="head_item">
          <div class="item_one">已下架的商品数</div>
          <div>{{ MainInfo.off_goods_count }}</div>
        </div>
      </div>
      <div class="head_two">
        <div class="head_title">商品转化</div>
        <div class="head_item">
          <div class="item_one">加购件数</div>
          <div>{{ MainInfo.cart_goods_count }}</div>
        </div>
        <div class="head_item">
          <div class="item_one">下单件数</div>
          <div>{{ MainInfo.order_goods_count }}</div>
        </div>
        <div class="head_item">
          <div class="item_one">支付件数</div>
          <div>{{ MainInfo.pay_goods_count }}</div>
        </div>
      </div>
    </div>
    <div class="table_main">
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>商品销售数据</div>
      </div>
      <StatisticsTime
        style="padding-left: 11px"
        @timeList="timeList"
        :prop-time="'全部'"
      ></StatisticsTime>
      <!-- //表格数据 -->
      <div class="table_info">
        <div class="right" style="margin-top: 10px">
          <el-button
            type="primary"
            @click="exportGoodLists"
            v-if="
              module_id_array.includes('161') || module_id_array.includes('999')
            "
            >导出商品明细</el-button
          >
        </div>
        <div class="table-box">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; min-width: 1000px"
            :header-cell-style="{ background: '#F5F5F5' }"
            @sort-change="sortChange"
          >
            <el-table-column label="商品信息" width="300">
              <template slot-scope="scope">
                <div class="flex align-center">
                  <div class="imgbox">
                    <el-image
                      :src="scope.row.picture_str"
                      alt
                      class="goodsImg"
                      fit="contain"
                      lazy
                    />
                  </div>
                  <div>
                    <span>{{ scope.row.goods_name }}</span
                    ><span v-if="scope.row.sku_name"
                      >【{{ scope.row.sku_name }}】</span
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="支付订单数"
              prop="order_count"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              label="支付买家数"
              prop="user_count"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              label="支付件数"
              prop="num"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              label="支付金额"
              prop="sum_pay_money"
              min-width="100"
              sortable="custom"
            ></el-table-column>
            <el-table-column
              label="成功退款订单数"
              prop="service_count"
              min-width="100"
              sortable="custom"
            ></el-table-column>
          </el-table>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin: 30px 0 40px 0"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import StatisticsTime from "@/components/common/StatisticsTime";
export default {
  components: {
    StatisticsTime,
  },
  data() {
    return {
      MainInfo: {}, //主要信息
      tableData: [], //表格数据
      total: 0,
      page: 1,
      pageNumber: 10,
      currentTimeList: [],
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },
  created() {
    this.getMainInfo();
    this.getList();
  },
  methods: {
    exportGoodLists() {
      const { start, end } = this.currentTimeList;
      const data = {
        page: this.page,
        pageNumber: this.pageNumber,
        create_time_start: start, //开始时间
        create_time_end: end, //结束时间
        order_sort: this.order_sort,
      };
      console.log("data", data);
      this.axios
        .post("/store/shopgoods/exportGoodsSurveyLists", data)
        .then((res) => {
          let host =
            document.location.protocol +
            "//" +
            window.location.host +
            res.data.url;

          window.open(host);
        });
    },
    sortChange(e) {
      let prop = e.prop;
      let order = e.order;
      if (prop == "order_count" && order == "ascending") {
        this.order_sort = 1;
      } else if (prop == "order_count" && order == "descending") {
        this.order_sort = 0;
      } else if (prop == "user_count" && order == "ascending") {
        this.order_sort = 3;
      } else if (prop == "user_count" && order == "descending") {
        this.order_sort = 2;
      } else if (prop == "num" && order == "ascending") {
        this.order_sort = 5;
      } else if (prop == "num" && order == "descending") {
        this.order_sort = 4;
      } else if (prop == "sum_pay_money" && order == "ascending") {
        this.order_sort = 7;
      } else if (prop == "sum_pay_money" && order == "descending") {
        this.order_sort = 6;
      } else if (prop == "service_count" && order == "ascending") {
        this.order_sort = 9;
      } else if (prop == "service_count" && order == "descending") {
        this.order_sort = 8;
      }
      this.getList();
    },
    // 获取页面信息
    getMainInfo() {
      this.axios.post("/store/shopgoods/getGoodsSurvey").then((res) => {
        let list = res.data.info;
        this.MainInfo = list;
      });
    },
    // 获取表格数据
    getList() {
      this.tableData = [];
      const { start, end } = this.currentTimeList;
      const data = {
        page: this.page,
        pageNumber: this.pageNumber,
        create_time_start: start, //开始时间
        create_time_end: end, //结束时间
        order_sort: this.order_sort,
      };
      console.log("data", data);
      this.axios
        .post("/store/shopgoods/getgoodssurveylist", data)
        .then((res) => {
          this.total = res.data.total;
          let list = res.data.list;
          this.tableData = list;
        });
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    timeList(val) {
      console.log("999", val);
      this.currentTimeList = val;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}
.over_head {
  margin-bottom: 30px;
}
.over_head {
  font-style: 14px;
  font-weight: bold;
  .head_one {
    display: flex;
    align-items: center;
    height: 110px;
    padding: 0 40px;
    border-bottom: 1px solid #e8e9f0;
  }
  .head_two {
    display: flex;
    align-items: center;
    height: 110px;
    padding: 0 40px;
    border-bottom: 1px solid #e8e9f0;
  }
  .head_title {
    width: 200px;
    font-size: 16px;
  }
  .head_item {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .item_one {
      padding-bottom: 20px;
    }
  }
}
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.table_main {
  padding: 0 20px;
}
.imgbox {
  width: 76px;
  height: 76px;
}
.goodsImg {
  width: 76px;
  height: 76px;
  padding-right: 5px;
}
</style> 

